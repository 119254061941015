// 保护区划界线描述与管理规定
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'HCQMJ',
                label: '缓冲区面积（公顷）',
                minWidth: 125,
            }, {
                prop: 'YCQMJ',
                label: '遗产区面积（公顷）',
                minWidth: 125,
            }],
            // 操作列
            columnsBtn: {
                buttonData: []
            },
        },
    },
};
export default selfData;